import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from "./shared/page-not-found/page-not-found.component";
import { AuthGuard } from "./core/auth.guard";
// import { AllProductsComponent } from './vendor/layuots/All-Product/VenderAllProduct.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },
  // { path: 'all-products', component: AllProductsComponent },
  {
    path: '',
    loadChildren: () => import('./vendor/vendor.module').then(m => m.VendorModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./mondi-boy/mondi-boy.module').then(m => m.MondiBoyModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
  },
  {
    path: 'accounts',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
