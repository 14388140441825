import { Injectable } from "@angular/core";
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const CurrentUser = JSON.parse(sessionStorage.getItem('CurrentUser'))
    if (CurrentUser == undefined && CurrentUser == null) {
      this.router.navigate(['/auth']);
      return false;
    } else {
      if (CurrentUser.Status == true) {
        return true;
      }
    }
  }
}