import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { CoreModule } from "./core/core.module";
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CustomHttpInterceptorService } from "./Services/custom-http-interceptor.service";
// import { enquiriesComponent } from './admin/enquiries/enquiries.component';



@NgModule({
  declarations: [AppComponent,
    // enquiriesComponent
    
  ],
  
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    HttpClientModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptorService, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
