import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { finalize } from "rxjs/operators";
import { SpinnerService } from "../Services/spinner.service";
@Injectable({
  providedIn: 'root'
})
export class CustomHttpInterceptorService {
  constructor(private spinnerService: SpinnerService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     this.spinnerService.show();
     return next.handle(req).pipe(
         finalize(() => this.spinnerService.hide())
     );
 }
}
